import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import InputField from '@/components/InputField/InputField.vue';
import JSPage from '@/classes/JSPage';
import CustomerService from '@/services/CustomerService';
import Customer from '@/models/Customer';
let CustomerAccountTab = class CustomerAccountTab extends JSPage {
    constructor() {
        super(...arguments);
        this.customerService = new CustomerService();
    }
    created() {
        super.created();
    }
    main() { }
    update() {
        let c = this.customer;
        if (c) {
            if (!c.id) {
                this.customerService.add(c).subscribe((customer) => {
                    this.$router.replace(customer.id.toString());
                }, (error) => {
                });
            }
            else {
                this.customerService.update(c).subscribe((customer) => {
                    this.$emit('input', new Customer(customer));
                });
            }
        }
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], CustomerAccountTab.prototype, "customer", void 0);
CustomerAccountTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, InputField
        }
    })
], CustomerAccountTab);
export default CustomerAccountTab;
