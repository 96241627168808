import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import InputField from '@/components/InputField/InputField.vue';
import JSPage from '@/classes/JSPage';
import BookService from '@/services/BookService';
import Book from '@/models/Book';
import CustomerService from '@/services/CustomerService';
import { ForeignEntry } from '@/models/ForeignEntry';
import BookMainPrint from './BookMainPrint/BookMainPrint.vue';
let BookMainTab = class BookMainTab extends JSPage {
    constructor() {
        super(...arguments);
        this.bookService = new BookService();
        this.status = [
            new ForeignEntry(0, "Provisional"),
            new ForeignEntry(1, "Definitiva")
        ];
        this.customers = new Array();
        this.doPrint = false;
        this.changed = false;
    }
    onBookChanged() {
        this.changed = false;
        if (this.book && this.book.id && this.book.id.valueOf() > 0 && this.customers.length > 0) {
            if (!this.customers.find(c => c.value === this.book.id_customer.value)) {
                let aux = new ForeignEntry(this.book.id_customer.value, this.book.id_customer.strValue);
                aux.strValue += ' (Eliminado)';
                this.customers.push(aux);
            }
        }
    }
    created() {
        super.created();
        this.loadCustomers();
    }
    main() { }
    loadCustomers() {
        (new CustomerService()).getAll(undefined, 'name=ASC', 1, 9999).subscribe((customersData) => {
            this.customers = customersData.data.map(customer => new ForeignEntry(customer.id, customer.name));
            if (this.book && this.book.id && this.book.id.valueOf() > 0) {
                if (!this.customers.find(c => c.value === this.book.id_customer.value)) {
                    let aux = new ForeignEntry(this.book.id_customer.value, this.book.id_customer.strValue);
                    aux.strValue += ' (Eliminado)';
                    this.customers.push(aux);
                }
            }
        });
    }
    update() {
        let b = new Book(this.book);
        if (b) {
            if (!b.id) {
                this.bookService.add(b).subscribe((book) => {
                    this.$router.replace(book.id.toString());
                }, (error) => {
                });
            }
            else {
                this.bookService.update(b).subscribe((book) => {
                    this.$emit('input', new Book(book));
                });
            }
        }
    }
    onChanged() {
        this.changed = true;
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], BookMainTab.prototype, "book", void 0);
tslib_1.__decorate([
    Watch('book')
], BookMainTab.prototype, "onBookChanged", null);
BookMainTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, InputField, BookMainPrint
        }
    })
], BookMainTab);
export default BookMainTab;
