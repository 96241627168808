import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import UserService from '@/services/UserService';
import JSPage from '@/classes/JSPage';
let UsersPage = class UsersPage extends JSPage {
    constructor() {
        super(...arguments);
        this.userService = new UserService();
        this.headers = [];
        this.filters = [];
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'Email', value: 'email' },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
    }
    setFilters() {
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' },
            { text: 'Email', value: 'email', content: '', toggle: true, type: 'text' }
        ];
    }
};
UsersPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], UsersPage);
export default UsersPage;
