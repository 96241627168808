import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import 'jquery';
import DatePicker from '../Base/DatePicker/DatePicker.vue';
let InputField = class InputField extends Vue {
    constructor() {
        super(...arguments);
        this._id = '';
    }
    onItemsChanged() {
        if (this.required && (this.type === 'select' || this.type === 'foreign') && this.value.value === -1) {
            this.$emit('input', this.items[0]);
        }
    }
    mounted() {
        if (this.autofocus !== undefined) {
            this.$el.getElementsByTagName('input')[0].focus();
        }
        if (this.tooltip) {
            setTimeout(() => {
                $('[data-toggle="tooltip"]').tooltip();
            }, 1000);
        }
    }
    getId() {
        if (this._id == '' && (this.id == '' || this.id === undefined)) {
            this._id = Math.floor(Math.random() * (9999999999 - 1000000000) + 1000000000).toString();
        }
        return this._id;
    }
    onSwitchSelected(event, value, items) {
        let newValue = event.target.attributes['value'].value;
        var oldValue = value;
        var newElem = newValue;
        if (value instanceof Object) {
            oldValue = value.value;
            newElem = items.filter((elem) => elem.value == newValue)[0];
        }
        if (newValue != oldValue) {
            this.$emit('input', newElem);
        }
        else {
            this.$emit('input', undefined);
        }
        // ( event.target.value != ( value instanceof Object ? value.value : value ) )
        // ? this.$emit( 'input', value instanceof Object ? items.filter( elem => elem.value == event.target.value )[ 0 ] : event.target.value ) 
        // : this.$emit( 'input', undefined )        
    }
    onSwitchClicked(event, value, items) {
        event.preventDefault();
        var targetValue = event.target.value;
        var value_ = targetValue;
        if (value instanceof Object) {
            value_ = value.value;
        }
        if (targetValue && !event.target.checked) {
            if (items.filter((elem) => elem.value == targetValue)[0]) {
                this.$emit('input', value instanceof Object ?
                    items.filter((elem) => elem.value == targetValue)[0] :
                    items.filter((elem) => elem.value == targetValue)[0].value);
            }
            else {
                this.$emit('input', targetValue);
            }
        }
        else {
            this.$emit('input', undefined);
        }
    }
    toggle() {
        if (this.value.length === this.items.length) {
            this.$emit('input', []);
        }
        else {
            this.$emit('input', this.items.map((item) => item.value));
        }
    }
    chipClose(index) {
        this.value.splice(index, 1);
    }
    onInput(event) {
        this.$emit('input', event);
        this.$parent.$forceUpdate();
    }
};
tslib_1.__decorate([
    Prop({ default: 'text' })
], InputField.prototype, "type", void 0);
tslib_1.__decorate([
    Prop()
], InputField.prototype, "id", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "label", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "required", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "requiredNotFirst", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "readonly", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "disabled", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "outlined", void 0);
tslib_1.__decorate([
    Prop({ default: 150 })
], InputField.prototype, "textareaHeight", void 0);
tslib_1.__decorate([
    Prop({ default: true })
], InputField.prototype, "autogrow", void 0);
tslib_1.__decorate([
    Prop({ default: 1 })
], InputField.prototype, "rows", void 0);
tslib_1.__decorate([
    Prop({ default: 99 })
], InputField.prototype, "maxlength", void 0);
tslib_1.__decorate([
    Prop({ default: 0 })
], InputField.prototype, "min", void 0);
tslib_1.__decorate([
    Prop({ default: 99 })
], InputField.prototype, "max", void 0);
tslib_1.__decorate([
    Prop({ default: 1 })
], InputField.prototype, "step", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "items", void 0);
tslib_1.__decorate([
    Prop({ default: 'strValue' })
], InputField.prototype, "itemField", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "placeholder", void 0);
tslib_1.__decorate([
    Prop()
], InputField.prototype, "autofocus", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "tooltip", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "popup", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "link", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "currency", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "hint", void 0);
tslib_1.__decorate([
    Prop({ default: 'primary' })
], InputField.prototype, "color", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "appendIcon", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "multiple", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "canSelectAll", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], InputField.prototype, "dense", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "textareaColor", void 0);
tslib_1.__decorate([
    Prop({ default: 'left' })
], InputField.prototype, "align", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "minDate", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], InputField.prototype, "maxDate", void 0);
tslib_1.__decorate([
    Watch('items')
], InputField.prototype, "onItemsChanged", null);
InputField = tslib_1.__decorate([
    Component({
        components: {
            DatePicker
        }
    })
], InputField);
export default InputField;
