import Assets from "@/classes/Assets";
import Helpers from "@/classes/Helpers";
export default class ImageField {
    constructor(image) {
        this._notFoundImage = false;
        this._DEFAULT_IMAGE = Assets.getImage('default.jpg');
        this._image = image;
        this._name = 'image_' + Helpers.getRandomInt().toString();
    }
    get image() {
        if (this._image_FILE) {
            this._image = this._image_FILE;
        }
        return this._image;
    }
    get name() {
        return this._name;
    }
    getImageSrc() {
        return (this.image && this.image !== '' && !this._notFoundImage) ?
            this.image :
            this.getDefaultImage();
    }
    getDefaultImage() {
        return this._DEFAULT_IMAGE;
    }
    catchIconError() {
        this._notFoundImage = true;
    }
    setImage(event, data) {
        this._image_FILE = event;
        this._image = data;
    }
    getImageFile() {
        return this._image_FILE;
    }
    hasFile() {
        return this._image_FILE !== undefined;
    }
    isEmpty() {
        return !(this.image && this.image !== '' && !this._notFoundImage);
    }
}
