export default class Customer {
    constructor(data) {
        this.name = '';
        this.address = '';
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
    }
    isDeleted() {
        return this.deleted_at != undefined;
    }
    toNormalize() {
        let reg = Object.assign({}, this);
        delete (reg.id);
        return reg;
    }
}
