import { EnumEntry } from './EnumEntry';
export class User {
    constructor(data) {
        this.name = '';
        this.email = '';
        this.type = new EnumEntry();
        this.id = 0;
        if (data) {
            Object.assign(this, data);
            this.ts_expiration = data.ts_expiration ? data.ts_expiration : new Date();
        }
    }
    getTypeStr() {
        switch (this.type.value) {
            case User.TYPE_ADMINISTRATOR:
                return 'Administrador';
            default:
                return '';
        }
    }
    toNormalize() {
        let user = Object.assign({}, this);
        if (user.type instanceof Object) {
            user.type = user.type.value;
        }
        delete (user.id);
        delete (user.token);
        delete (user.ts_expiration);
        delete (user.last_access);
        return user;
    }
    isAdmin() {
        return this.type && this.type.value === User.TYPE_ADMINISTRATOR;
    }
    isLogged() {
        return this.token && this.token !== '';
    }
}
User.TYPE_ADMINISTRATOR = 99;
