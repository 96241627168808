import Vue from 'vue';
import VueRouter from 'vue-router';
import NotFoundPage from "@/pages/NotFound/NotFoundPage.vue";
import LoginPage from "@/pages/Login/LoginPage.vue";
import DashboardPage from "@/pages/Dashboard/DashboardPage.vue";
import UsersPage from '@/pages/Users/UsersPage.vue';
import UserPage from '@/pages/Users/UserPage/UserPage.vue';
import ProfilePage from "@/pages/Profile/ProfilePage.vue";
import { User } from '@/models/User';
import CustomersPage from '@/pages/Customers/CustomersPage.vue';
import CustomerPage from '@/pages/Customers/CustomerPage/CustomerPage.vue';
import CategoriesPage from '@/pages/Categories/CategoriesPage.vue';
import ArticlesPage from '@/pages/Articles/ArticlesPage.vue';
import ArticlePage from '@/pages/Articles/ArticlePage/ArticlePage.vue';
import BooksPage from '@/pages/Books/BooksPage.vue';
import BookPage from '@/pages/Books/BookPage/BookPage.vue';
import AvailabilityPage from '@/pages/Availability/AvailabilityPage.vue';
import StatsPage from '@/pages/Stats/StatsPage.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'login',
        component: LoginPage
    },
    {
        path: '/dashboard',
        name: 'Principal',
        component: DashboardPage
    },
    {
        path: '/articles',
        name: 'Artículos',
        component: ArticlesPage
    },
    {
        path: '/articles/:id',
        name: 'Datos del artículo',
        component: ArticlePage
    },
    {
        path: '/availability',
        name: 'Disponibilidad',
        component: AvailabilityPage
    },
    {
        path: '/books',
        name: 'Reservas',
        component: BooksPage
    },
    {
        path: '/books/:id',
        name: 'Datos de la reserva',
        component: BookPage
    },
    {
        path: '/customers',
        name: 'Clientes',
        component: CustomersPage
    },
    {
        path: '/customers/:id',
        name: 'Datos del cliente',
        component: CustomerPage
    },
    {
        path: '/stats',
        name: 'Estadísticas de uso',
        component: StatsPage
    },
    {
        path: '/system/administrators',
        name: 'Administradores',
        props: { type: User.TYPE_ADMINISTRATOR },
        component: UsersPage
    },
    {
        path: '/system/administrators/:id',
        name: 'Datos del administrador',
        props: { type: User.TYPE_ADMINISTRATOR },
        component: UserPage
    },
    {
        path: '/system/categories',
        name: 'Categorías',
        props: { type: User.TYPE_ADMINISTRATOR },
        component: CategoriesPage
    },
    {
        path: '/profile',
        name: 'Mi perfil',
        component: ProfilePage
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFoundPage
    }
];
const router = new VueRouter({
    routes,
    mode: 'history'
});
export default router;
