import BaseService from './BaseService';
import { Observable } from "rxjs";
import { CBlocker } from '@/components/Blocker/CBlocker';
export default class BookService {
    constructor() {
        this.path = 'books';
    }
    getAll(filter, order, page, length) {
        let content = Object();
        if (filter) {
            content.filter = filter;
        }
        if (order) {
            content.order = order;
        }
        if (page) {
            content.page = page;
        }
        if (length) {
            content.length = length;
        }
        return BaseService.get(this.path, content);
    }
    get(id) {
        return BaseService.get(this.path + '/' + id);
    }
    getNextNumber() {
        return BaseService.get(this.path + '/next');
    }
    add(reg) {
        CBlocker.showLoading();
        let content = reg.toNormalize();
        return new Observable((observer) => {
            BaseService.post(this.path, content, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Reserva creada');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    update(reg) {
        CBlocker.showLoading();
        let content = reg.toNormalize();
        return new Observable((observer) => {
            BaseService.put(this.path + '/' + reg.id, content, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('¡Actualizada!');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    delete(reg) {
        CBlocker.showLoading();
        return new Observable((observer) => {
            BaseService.delete(this.path + '/' + reg.id, undefined, false).subscribe((success) => {
                observer.next(success);
                CBlocker.showSuccess('Reserva eliminada');
            }, (error) => {
                observer.error(error);
                CBlocker.showError(error.message);
            });
        });
    }
    getEnums() {
        if (BookService.enums.length == 0) {
            return BaseService.get(this.path + '/enums');
        }
        else {
            return new Observable((observer) => {
                observer.next(BookService.enums);
            });
        }
    }
}
BookService.enums = new Array();
