var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (this.user.isLogged() && _vm.foundPage)?_c('v-app-bar',{attrs:{"id":"app-bar","absolute":"","app":"","flat":"","height":"75"}},[_c('v-btn',{staticClass:"mr-3",attrs:{"elevation":"1","fab":"","small":""},on:{"click":function($event){return _vm.setSidebarState(!_vm.sidebarState);}}},[(false)?_c('v-icon',[_vm._v(" mdi-view-quilt ")]):_c('v-icon',[_vm._v(" mdi-menu ")])],1),_c('v-toolbar-title',{staticClass:"hidden-sm-and-down font-weight-light",domProps:{"textContent":_vm._s(_vm.$route.name)}}),_c('div',{staticClass:"hidden-sm-and-up topbar_mobile_title"},[_vm._v(" Elecfes ")]),_c('v-spacer'),_c('div',{staticClass:"mx-3"}),_c('v-btn',{staticClass:"ml-2",attrs:{"min-width":"0","text":"","to":"/dashboard"}},[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":"","origin":"top right","transition":"scale-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"min-width":"0","text":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,64904057)},[_c('v-list',{attrs:{"tile":false,"nav":""}},[_c('div',[(_vm.user.isAdmin())?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:{ "black--text" : !hover, "white--text green lighten-2 elevation-12" : hover },attrs:{"active-class":"","dark":hover,"link":true,"to":"/profile"}},[(!_vm.user.image)?_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1):_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.user.image}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Mi perfil")])],1)],1)]}}],null,false,985644659)}):_vm._e(),(_vm.user.isAdmin())?_c('hr',{staticStyle:{"margin":"4px 0px"}}):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{class:{ "black--text" : !hover, "white--text red lighten-2 elevation-12" : hover },attrs:{"active-class":"","dark":hover,"link":true},on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Desconectar")])],1)],1)]}}],null,false,3392192328)})],1)])],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }