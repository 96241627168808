import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import LightBox from '../LightBox/LightBox';
import ImageField from '@/classes/ImageField';
let MaterialUploader = class MaterialUploader extends Vue {
    constructor() {
        super(...arguments);
        this.imageSrc = "";
        this.isDragging = false;
        this.isHover = false;
        this.fullscreen = false;
        this.DEFAULT_IMAGE = require('@/assets/images/default.jpg');
    }
    onSrcChange() {
        if (!(this.value instanceof ImageField)) {
            this.$emit('input', new ImageField(this.value));
        }
        else {
            if (!this.value.image || this.value.image === '') {
                this.imageSrc = this.DEFAULT_IMAGE;
            }
            else {
                this.imageSrc = this.value.image;
            }
        }
    }
    showFull() {
        LightBox.show(this.imageSrc);
    }
    fileInput() {
        return this.$refs.fileInput;
    }
    getImageSrc() {
        if (this.imageSrc instanceof File) {
            return '';
        }
        return this.imageSrc;
    }
    created() {
        this.onSrcChange();
    }
    mounted() {
        const image = document.querySelector('.image-uploader .image');
        if (image) {
            image.style.height = image.clientWidth / this.aspectRatio + 'px';
        }
    }
    imagePath() {
        return this.imageSrc !== this.DEFAULT_IMAGE ? this.imageSrc : '';
    }
    remove() {
        this.fileInput().value = '';
        this.onFileChanged('', undefined);
        this.imageSrc = this.DEFAULT_IMAGE;
    }
    inputTriggerClick() {
        this.fileInput().click();
    }
    onInputChanged() {
        if (this.fileInput().files && this.fileInput().files[0]) {
            const reader = new FileReader();
            const me = this;
            reader.onload = function (ev) {
                me.imageSrc = ev.target.result.toString();
                me.onFileChanged(ev.target.result, me.fileInput().files[0]);
            };
            reader.readAsDataURL(this.fileInput().files[0]);
        }
    }
    onFileChanged(data, file) {
        this.value.setImage(file, data);
    }
    onDragOver(ev) {
        ev.preventDefault();
        this.isDragging = true;
    }
    onDragLeave(ev) {
        ev.preventDefault();
        this.isDragging = false;
    }
    onDrop(ev) {
        ev.preventDefault();
        this.fileInput().files = ev.dataTransfer.files;
        this.isDragging = false;
        this.onInputChanged();
    }
};
tslib_1.__decorate([
    Prop({ required: true })
], MaterialUploader.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: 'Cambiar' })
], MaterialUploader.prototype, "text", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], MaterialUploader.prototype, "caption", void 0);
tslib_1.__decorate([
    Prop({ default: 'green' })
], MaterialUploader.prototype, "captionColor", void 0);
tslib_1.__decorate([
    Prop({ default: 1.5 })
], MaterialUploader.prototype, "aspectRatio", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], MaterialUploader.prototype, "readonly", void 0);
tslib_1.__decorate([
    Watch('value')
], MaterialUploader.prototype, "onSrcChange", null);
MaterialUploader = tslib_1.__decorate([
    Component
], MaterialUploader);
export default MaterialUploader;
