import { ForeignEntry } from "./ForeignEntry";
export class Article {
    constructor(data) {
        this.id_category = new ForeignEntry();
        this.code = '';
        this.name = '';
        this.features = '';
        this.power = 0;
        this.height = 0;
        this.width = 0;
        this.initial_stock = 0;
        this.image = '';
        this.images = [];
        this.stock = 0;
        this.quantity = 0;
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
    }
    clone() {
        let clone = new Article();
        clone.id = this.id;
        clone.id_category = new ForeignEntry(this.id_category.value, this.id_category.strValue);
        clone.code = this.code;
        clone.name = this.name;
        clone.features = this.features;
        clone.power = this.power;
        clone.height = this.height;
        clone.width = this.width;
        clone.initial_stock = this.initial_stock;
        clone.image = this.image;
        clone.images = this.images;
        clone.stock = this.stock;
        clone.quantity = this.quantity;
        clone.deleted_at = this.deleted_at;
        return clone;
    }
    toNormalize() {
        let article = Object.assign({}, this);
        delete (article.id);
        delete (article.quantity);
        article.images.forEach((img, index) => article['image_' + (index + 1)] = img);
        article.image = '';
        delete (article.images);
        return article;
    }
    isDeleted() {
        this.deleted_at != '';
    }
}
