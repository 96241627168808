import * as tslib_1 from "tslib";
import { Component, Prop } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import InputField from '@/components/InputField/InputField.vue';
import JSPage from '@/classes/JSPage';
import ContactService from '@/services/ContactService';
import DataTable from '@/components/DataTable/DataTable.vue';
let CustomerContactsTab = class CustomerContactsTab extends JSPage {
    constructor() {
        super(...arguments);
        this.contactService = new ContactService();
        this.headers = [];
        this.filters = [];
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'Cargo', value: 'position', align: 'start' },
            { text: 'Teléfono', value: 'phone', align: 'start' },
            { text: 'Móvil (Trabajo)', value: 'work_mobile', align: 'start' },
            { text: 'Email (Trabajo)', value: 'work_email', align: 'start' },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
    }
    setFilters() {
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' },
            { text: 'Cargo', value: 'position', content: '', toggle: true, type: 'text' },
            { text: 'Teléfono', value: 'phone', content: '', toggle: true, type: 'text' },
            { text: 'Móvil (Trabajo)', value: 'work_mobile', content: '', toggle: true, type: 'text' },
            { text: 'Email (Trabajo)', value: 'work_email', content: '', toggle: true, type: 'text' },
        ];
    }
    onNewItem(item) {
        item.id_customer = this.customer.id;
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], CustomerContactsTab.prototype, "customer", void 0);
CustomerContactsTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, InputField, DataTable
        }
    })
], CustomerContactsTab);
export default CustomerContactsTab;
