import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import JSPage from '@/classes/JSPage';
import InputField from '@/components/InputField/InputField.vue';
import moment from 'moment';
import BaseService from '@/services/BaseService';
import MaterialCard from '@/components/Base/MaterialCard/MaterialCard.vue';
import Router from '@/classes/Router';
import { ForeignEntry } from '@/models/ForeignEntry';
import ArticleService from '@/services/ArticleService';
let StatsPage = class StatsPage extends JSPage {
    constructor() {
        super(...arguments);
        this.headers = [];
        this.data = Array();
        this.on_date = moment().subtract(1, 'month').toDate();
        this.last_on_date = moment().toDate();
        this.article = new ForeignEntry();
        this.articles = new Array();
        this.initialized = false;
    }
    onDataChanged() {
        if (!this.initialized)
            return;
        if (!(this.article instanceof ForeignEntry)) {
            this.article = new ForeignEntry(this.article);
        }
        if (this.on_date && this.last_on_date) {
            Router.addQueryToURL('from', moment(this.on_date).format('YYYY-MM-DD'));
            Router.addQueryToURL('to', moment(this.last_on_date).format('YYYY-MM-DD'));
            var content = {
                from: moment(this.on_date).format('YYYY-MM-DD'),
                to: moment(this.last_on_date).format('YYYY-MM-DD')
            };
            if (this.article.value > 0) {
                Router.addQueryToURL('a', this.article.value);
                content.id_article = this.article.value;
            }
            else {
                Router.removeQueryFromURL('a');
            }
            BaseService.post('stats/stats', content, true).subscribe((data) => {
                this.data = data;
            });
        }
    }
    created() {
        super.created();
        this.on_date = moment(Router.getQueryVar('from', moment(this.on_date).format('YYYY-MM-DD'))).toDate();
        this.last_on_date = moment(Router.getQueryVar('to', moment(this.last_on_date).format('YYYY-MM-DD'))).toDate();
        let article = parseInt(Router.getQueryVar('a', -1));
        (new ArticleService()).getAll(undefined, 'name=ASC', 1, 9999).subscribe((articlesData) => {
            this.articles = articlesData.data.map(a => new ForeignEntry(a.id, a.name));
            this.article = this.articles.find(a => a.value === article);
            this.initialized = true;
            this.onDataChanged();
        });
    }
    main() {
        this.setHeaders();
    }
    setHeaders() {
        this.headers = [
            { text: 'Artículo', value: 'id_article.strValue', align: 'start', sortable: false },
            { text: 'Nº de reservas', value: 'bookings', align: 'center', sortable: false },
            { text: 'Días de uso', value: 'days', align: 'center', sortable: false },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
    }
    get printPostTitle() {
        var id = (this.article instanceof ForeignEntry) ? this.article.value : this.article;
        var art = this.articles.find(a => a.value === id);
        var ret = `
        <div style='font-size:12px;border:1px solid #999;background-color: #eee;padding:4px;width:99%;margin:0px auto 12px auto;'>
        `;
        if (art && art.value > 0) {
            ret += `<div><strong>Artículo: </strong>${art.strValue}</div>`;
        }
        ret += `
            <div><strong>Desde el </strong>${moment(this.on_date).format('DD/MM/YYYY')} <strong>al</strong> ${moment(this.last_on_date).format('DD/MM/YYYY')}</div>
        </div>`;
        return ret;
    }
};
tslib_1.__decorate([
    Watch('on_date'),
    Watch('last_on_date'),
    Watch('article')
], StatsPage.prototype, "onDataChanged", null);
StatsPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable, InputField, MaterialCard
        }
    })
], StatsPage);
export default StatsPage;
