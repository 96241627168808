import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import Tabs from '@/components/Base/Tabs/Tabs.vue';
import CustomerAccountTab from './Tabs/CustomerAccountTab/CustomerAccountTab.vue';
import CustomerContactsTab from './Tabs/CustomerContactsTab/CustomerContactsTab.vue';
import JSPage from '@/classes/JSPage';
import CustomerService from '@/services/CustomerService';
import Customer from '@/models/Customer';
let CustomerPage = class CustomerPage extends JSPage {
    constructor() {
        super(...arguments);
        this.customerService = new CustomerService();
        this.customer = new Customer();
        this.tabs = new Array();
        this.tab = '';
    }
    OnUserChanged() {
        this.loadTabs();
    }
    created() {
        super.created();
    }
    main() {
        this.loadTabs();
        this.loadData();
    }
    loadTabs() {
        this.tabs = [];
        this.tabs.push({ title: 'Cuenta', icon: 'mdi-account', href: 'main-tab' });
        if (this.customer.id > 0) {
            this.tabs.push({ title: 'Contactos', icon: 'mdi-contacts-outline', href: 'contact-tab' });
        }
    }
    loadData() {
        let id = this.$router.currentRoute.params.id;
        if (id && !isNaN(parseInt(id)) && parseInt(id) >= 0) {
            this.getCustomer();
        }
        else {
            if (id === 'new') {
                this.loadTabs();
            }
            else {
                this.cancel();
            }
        }
    }
    getCustomer() {
        let id = parseInt(this.$router.currentRoute.params.id);
        if (id) {
            this.customerService.get(id).subscribe((customer) => {
                this.customer = new Customer(customer);
                this.loadTabs();
            }, (error) => {
                this.cancel();
            });
        }
        else {
            this.cancel();
        }
    }
    update() {
        if (!this.customer.id) {
            this.customerService.add(this.customer).subscribe((customer) => {
                this.customer = new Customer(customer);
            }, (error) => { });
        }
        else {
            this.customerService.update(this.customer).subscribe((customer) => {
                this.customer = new Customer(customer);
            }, (error) => { });
        }
    }
    cancel() {
        this.$router.back();
    }
    onCustomerUpdate(event) {
        this.customer = event;
    }
    goBack() {
        this.$router.back();
    }
};
tslib_1.__decorate([
    Watch('customer')
], CustomerPage.prototype, "OnUserChanged", null);
CustomerPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, Tabs,
            CustomerAccountTab, CustomerContactsTab
        }
    })
], CustomerPage);
export default CustomerPage;
