import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import Tabs from '@/components/Base/Tabs/Tabs.vue';
import BookMainTab from './Tabs/BookMainTab/BookMainTab.vue';
import BookSitesTab from './Tabs/BookSitesTab/BookSitesTab.vue';
import JSPage from '@/classes/JSPage';
import BookService from '@/services/BookService';
import Book from '@/models/Book';
import Helpers from '@/classes/Helpers';
import Globals from '@/classes/Globals';
let BookPage = class BookPage extends JSPage {
    constructor() {
        super(...arguments);
        this.bookService = new BookService();
        this.book = new Book();
        this.tabs = new Array();
        this.tab = '';
        this.Helpers = Helpers;
    }
    OnUserChanged() {
        this.loadTabs();
    }
    created() {
        super.created();
    }
    main() {
        this.loadTabs();
        this.loadData();
    }
    loadTabs() {
        this.tabs = [];
        this.tabs.push({ title: 'Principal', icon: 'mdi-book-clock-outline', href: 'main-tab' });
        this.tabs.push({ title: 'Emplazamientos', icon: 'mdi-map-marker-outline', href: 'sites-tab' });
    }
    loadData() {
        let id = this.$router.currentRoute.params.id;
        if (id && !isNaN(parseInt(id)) && parseInt(id) >= 0) {
            this.getArticle();
        }
        else {
            if (id === 'new') {
                if (Globals.has('book')) {
                    this.book = Globals.get('book');
                }
                this.getNextNumber();
                this.loadTabs();
            }
            else {
                this.cancel();
            }
        }
        Globals.remove('book');
    }
    getNextNumber() {
        this.bookService.getNextNumber().subscribe((next) => this.book.number = next);
    }
    getArticle() {
        let id = parseInt(this.$router.currentRoute.params.id);
        if (id) {
            this.bookService.get(id).subscribe((book) => {
                this.book = new Book(book);
                this.loadTabs();
            }, (error) => {
                this.cancel();
            });
        }
        else {
            this.cancel();
        }
    }
    update() {
        if (!this.book.id) {
            this.bookService.add(this.book).subscribe((book) => {
                this.book = new Book(book);
            }, (error) => { });
        }
        else {
            this.bookService.update(this.book).subscribe((book) => {
                this.book = new Book(book);
            }, (error) => { });
        }
    }
    cancel() {
        Globals.remove('book');
        this.$router.back();
    }
    onBookUpdate(event) {
        this.book = event;
    }
    goBack() {
        this.$router.back();
    }
};
tslib_1.__decorate([
    Watch('book')
], BookPage.prototype, "OnUserChanged", null);
BookPage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, Tabs,
            BookMainTab, BookSitesTab
        }
    })
], BookPage);
export default BookPage;
