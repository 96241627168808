import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import JSPage from '@/classes/JSPage';
import InputField from '@/components/InputField/InputField.vue';
import moment from 'moment';
import BaseService from '@/services/BaseService';
import ArticleService from '@/services/ArticleService';
import { ForeignEntry } from '@/models/ForeignEntry';
import MaterialCard from '@/components/Base/MaterialCard/MaterialCard.vue';
import Router from '@/classes/Router';
let AvailabilityPage = class AvailabilityPage extends JSPage {
    constructor() {
        super(...arguments);
        this.headers = [];
        this.data = Array();
        this.on_date = moment().subtract(1, 'month').toDate();
        this.last_on_date = moment().toDate();
        this.article = new ForeignEntry();
        this.articles = new Array();
    }
    onDataChanged() {
        if (!(this.article instanceof ForeignEntry)) {
            this.article = new ForeignEntry(this.article);
        }
        if (this.article.value > 0 && this.on_date && this.last_on_date) {
            Router.addQueryToURL('a', this.article.value);
            Router.addQueryToURL('from', moment(this.on_date).format('YYYY-MM-DD'));
            Router.addQueryToURL('to', moment(this.last_on_date).format('YYYY-MM-DD'));
            BaseService.post('stats/availability', {
                id_article: this.article,
                from: moment(this.on_date).format('YYYY-MM-DD'),
                to: moment(this.last_on_date).format('YYYY-MM-DD')
            }, true).subscribe((data) => {
                this.data = data;
            });
        }
    }
    created() {
        super.created();
        this.on_date = moment(Router.getQueryVar('from', moment(this.on_date).format('YYYY-MM-DD'))).toDate();
        this.last_on_date = moment(Router.getQueryVar('to', moment(this.last_on_date).format('YYYY-MM-DD'))).toDate();
        let article = parseInt(Router.getQueryVar('a', -1));
        (new ArticleService()).getAll(undefined, 'name=ASC', 1, 9999).subscribe((articlesData) => {
            this.articles = articlesData.data.map(a => new ForeignEntry(a.id, a.name));
            this.article = this.articles.find(a => a.value === article);
            this.onDataChanged();
        });
    }
    main() {
        this.setHeaders();
    }
    setHeaders() {
        this.headers = [
            { text: 'Reserva', value: 'id_book.strValue', align: 'start', sortable: false },
            { text: 'Cliente', value: 'id_customer.strValue', align: 'start', sortable: false },
            { text: 'Emplazamiento', value: 'id_site.strValue', align: 'start', sortable: false },
            { text: 'Inicio', value: 'start_date', align: 'center', type: IDataTableHeaderType.DATE, sortable: false },
            { text: 'Fin', value: 'end_date', align: 'center', type: IDataTableHeaderType.DATE, sortable: false },
            { text: 'Cantidad', value: 'quantity', align: 'center', sortable: false },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
    }
    onItemSelected(item) {
        Router.goTo('books/' + item.id_book.value + '#sites-tab');
    }
    get articleRequired() {
        return this.article && ((this.article instanceof ForeignEntry) ? this.article.value > 0 : this.article > 0);
    }
    get printPostTitle() {
        var id = (this.article instanceof ForeignEntry) ? this.article.value : this.article;
        var art = this.articles.find(a => a.value === id);
        if (art && art.value > 0) {
            return `
            <div style='font-size:12px;border:1px solid #999;background-color: #eee;padding:4px;width:99%;margin:0px auto 12px auto;'>
                <div><strong>Artículo: </strong>${art.strValue}</div>
                <div><strong>Desde el </strong>${moment(this.on_date).format('DD/MM/YYYY')} <strong>al</strong> ${moment(this.last_on_date).format('DD/MM/YYYY')}</div>
            </div>
            `;
        }
        return '';
    }
};
tslib_1.__decorate([
    Watch('on_date'),
    Watch('last_on_date'),
    Watch('article')
], AvailabilityPage.prototype, "onDataChanged", null);
AvailabilityPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable, InputField, MaterialCard
        }
    })
], AvailabilityPage);
export default AvailabilityPage;
