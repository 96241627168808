import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import InputField from '@/components/InputField/InputField.vue';
import JSPage from '@/classes/JSPage';
import BookService from '@/services/BookService';
import { Article } from '@/models/Article';
import Site from '@/models/Site';
import { CBlocker } from '@/components/Blocker/CBlocker';
import ArticleService from '@/services/ArticleService';
import { ForeignEntry } from '@/models/ForeignEntry';
import Book from '@/models/Book';
let BookSitesTab = class BookSitesTab extends JSPage {
    constructor() {
        super(...arguments);
        this.bookService = new BookService();
        this.articles = new Array();
        this.selectedIndex = -1;
        this.document = window.document;
    }
    onBookChanged() {
        if (!this.prevBook) {
            this.prevBook = new Book(this.book);
        }
        this.loadArticles();
    }
    created() {
        super.created();
    }
    main() {
        // this.loadArticles();
    }
    get articlesForeign() {
        return this.articles.map(a => new ForeignEntry(a.id, a.name));
    }
    get codesForeign() {
        return this.articles.map(a => new ForeignEntry(a.id, a.code));
    }
    loadArticles(callback) {
        let params = {
            from: this.book.on_date,
            to: this.book.last_on_date
        };
        (new ArticleService()).getAll(undefined, 'name=ASC', 1, 9999, params).subscribe((articlesData) => {
            this.articles = articlesData.data.map(a => new Article(a));
            if (callback) {
                callback();
            }
        });
    }
    onBlurQuantity(article) {
        if (article.quantity < 1) {
            article.quantity = 1;
        }
    }
    getPrevStock(article) {
        if (this.prevBook) {
            let total = 0;
            this.prevBook.sites.forEach(s => {
                s.articles.filter(a => a.article.id === article.article.id).forEach(a => total = Number(total) + Number(a.quantity));
            });
            return total;
        }
        return 0;
    }
    getStock(article) {
        if (this.articles.length > 0 && article.article.id > 0) {
            let total = 0;
            this.book.sites.forEach(s => {
                s.articles.filter(a => a.article.id === article.article.id).forEach(a => total = Number(total) + Number(a.quantity));
            });
            let art = this.articles.find(a => a.id === article.article.id);
            if (art) {
                return art.stock - total + this.getPrevStock(article);
            }
        }
        return 0;
    }
    hasAnyOutOfStockArticle(site) {
        return site.articles.filter(a => this.getStock(a) < 0).length > 0;
    }
    addSite() {
        var site = new Site();
        site.id_book = this.book.id.valueOf();
        site.expanded = true;
        this.book.sites.push(site);
    }
    deleteSite(index, event) {
        event.preventDefault();
        event.stopPropagation();
        CBlocker.showConfirm('¿Deseas eliminar este emplazamiento?', () => {
            this.book.sites.splice(index, 1);
        }, () => CBlocker.hide());
    }
    duplicateSite(index, event) {
        event.preventDefault();
        event.stopPropagation();
        CBlocker.showConfirm('¿Deseas duplicar este emplazamiento?', () => {
            let site = this.book.sites[index].clone();
            this.book.sites.push(site);
        }, () => CBlocker.hide());
    }
    addArticle(index) {
        this.selectedIndex = index;
        this.book.sites[this.selectedIndex].articles.push({
            article: new Article(),
            quantity: 1,
            imageIndex: 0,
            order: this.book.sites[this.selectedIndex].articles.length + 1
        });
    }
    duplicateArticle(site, index) {
        CBlocker.showConfirm('¿Deseas duplicar este artículo en este emplazamiento?', () => {
            site.articles.push({
                article: site.articles[index].article.clone(),
                quantity: site.articles[index].quantity,
                imageIndex: 0,
                order: site.articles.length
            });
        }, () => CBlocker.hide());
    }
    removeArticle(site, index) {
        CBlocker.showConfirm('¿Deseas eliminar este artículo del emplazamiento?', () => {
            site.articles.splice(index, 1);
        }, () => CBlocker.hide());
    }
    update() {
        let b = new Book(this.book);
        if (b) {
            if (!b.id) {
                this.bookService.add(b).subscribe((book) => {
                    this.$router.replace(book.id.toString());
                }, (error) => {
                });
            }
            else {
                this.bookService.update(b).subscribe((book) => {
                    this.$emit('input', new Book(book));
                    this.prevBook = new Book(this.book);
                    this.loadArticles();
                });
            }
        }
    }
    onSet(article, item) {
        article.article = this.articles.find(a => a.id === item.value);
    }
    previousIndex(article) {
        article.imageIndex--;
        if (article.imageIndex < 0) {
            article.imageIndex = article.article.images.length - 1;
        }
    }
    nextIndex(article) {
        article.imageIndex++;
        if (article.imageIndex > article.article.images.length - 1) {
            article.imageIndex = 0;
        }
    }
    copyToClipboard(str) {
        navigator.clipboard.writeText(str);
    }
    toggleSite(site) {
        let state = $('#site-' + site.id).find('.accordion-button').hasClass('.collapsed');
        site.expanded = !state;
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], BookSitesTab.prototype, "book", void 0);
tslib_1.__decorate([
    Watch('book')
], BookSitesTab.prototype, "onBookChanged", null);
BookSitesTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, InputField
        }
    })
], BookSitesTab);
export default BookSitesTab;
