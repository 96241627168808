import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import { IDataTableHeaderType } from '@/components/DataTable/interfaces/IDataTableHeader';
import JSPage from '@/classes/JSPage';
import ArticleService from '@/services/ArticleService';
import CategoryService from '@/services/CategoryService';
import Category from '@/models/Category';
import Router from '@/classes/Router';
let ArticlesPage = class ArticlesPage extends JSPage {
    constructor() {
        super(...arguments);
        this.articleService = new ArticleService();
        this.headers = [];
        this.filters = [];
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Imagen', value: 'image', align: 'start', type: IDataTableHeaderType.IMAGE, maxWidth: 80, unprintable: true },
            { text: 'Código', value: 'code', align: 'start' },
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'Categoría', value: 'id_category.strValue', align: 'start' },
            { text: 'Cantidad', value: 'initial_stock', align: 'end' },
            { text: 'Disponibles', value: 'stock', align: 'end' },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
    }
    setFilters() {
        this.filters = [
            { text: 'Código', value: 'code', content: '', toggle: true, type: 'text' },
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' },
            { text: 'Categoría', value: 'id_category', content: '', toggle: true, type: 'foreign',
                foreignService: new CategoryService(), field: 'name', order: 'name=ASC', class: Category },
        ];
    }
    getAvailability(item) {
        Router.goTo('availability?a=' + item.id);
    }
    get printTitle() {
        return 'Listado de Artículos';
    }
};
ArticlesPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable
        }
    })
], ArticlesPage);
export default ArticlesPage;
