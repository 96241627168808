import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/es';
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
//Vue.mixin( mixins );
new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');
