import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import Tabs from '@/components/Base/Tabs/Tabs.vue';
import ArticleMainTab from './Tabs/ArticleMainTab/ArticleMainTab.vue';
import JSPage from '@/classes/JSPage';
import ArticleService from '@/services/ArticleService';
import { Article } from '@/models/Article';
let ArticlePage = class ArticlePage extends JSPage {
    constructor() {
        super(...arguments);
        this.articleService = new ArticleService();
        this.article = new Article();
        this.tabs = new Array();
        this.tab = '';
    }
    OnUserChanged() {
        this.loadTabs();
    }
    created() {
        super.created();
    }
    main() {
        this.loadTabs();
        this.loadData();
    }
    loadTabs() {
        this.tabs = [];
        this.tabs.push({ title: 'Principal', icon: 'mdi-archive', href: 'main-tab' });
    }
    loadData() {
        let id = this.$router.currentRoute.params.id;
        if (id && !isNaN(parseInt(id)) && parseInt(id) >= 0) {
            this.getArticle();
        }
        else {
            if (id === 'new') {
                this.loadTabs();
            }
            else {
                this.cancel();
            }
        }
    }
    getArticle() {
        let id = parseInt(this.$router.currentRoute.params.id);
        if (id) {
            this.articleService.get(id).subscribe((article) => {
                this.article = new Article(article);
                this.loadTabs();
            }, (error) => {
                this.cancel();
            });
        }
        else {
            this.cancel();
        }
    }
    update() {
        if (!this.article.id) {
            this.articleService.add(this.article).subscribe((article) => {
                this.article = new Article(article);
            }, (error) => { });
        }
        else {
            this.articleService.update(this.article).subscribe((article) => {
                this.article = new Article(article);
            }, (error) => { });
        }
    }
    cancel() {
        this.$router.back();
    }
    onArticleUpdate(event) {
        this.article = event;
    }
    goBack() {
        this.$router.back();
    }
};
tslib_1.__decorate([
    Watch('article')
], ArticlePage.prototype, "OnUserChanged", null);
ArticlePage = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, Tabs,
            ArticleMainTab
        }
    })
], ArticlePage);
export default ArticlePage;
