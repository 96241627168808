import * as tslib_1 from "tslib";
import { Component } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import InputField from '@/components/InputField/InputField.vue';
import JSPage from '@/classes/JSPage';
import CategoryService from '@/services/CategoryService';
import Category from '@/models/Category';
let CategoriesPage = class CategoriesPage extends JSPage {
    constructor() {
        super(...arguments);
        this.categoryService = new CategoryService();
        this.Category = Category;
        this.headers = [];
        this.filters = [];
    }
    created() {
        super.created();
    }
    main() {
        this.setHeaders();
        this.setFilters();
    }
    setHeaders() {
        this.headers = [
            { text: 'Nombre', value: 'name', align: 'start' },
            { text: 'Descripción', value: 'description', align: 'start', sortable: false },
            { text: '', value: 'actions', align: 'end', sortable: false }
        ];
    }
    setFilters() {
        this.filters = [
            { text: 'Nombre', value: 'name', content: '', toggle: true, type: 'text' }
        ];
    }
};
CategoriesPage = tslib_1.__decorate([
    Component({
        components: {
            DataTable, InputField
        }
    })
], CategoriesPage);
export default CategoriesPage;
