import * as tslib_1 from "tslib";
import { Component, Watch } from 'vue-property-decorator';
import DataPrint from "@/components/DataPrint/DataPrint";
import Assets from '@/classes/Assets';
import Helpers from '@/classes/Helpers';
let BookMainPrint = class BookMainPrint extends DataPrint {
    constructor() {
        super(...arguments);
        this.Assets = Assets;
        this.Helpers = Helpers;
        this.page = 1;
    }
    onDataChanged() {
        if (this.data) {
            this.overview = this.data.getOverview();
        }
    }
    created() {
        if (this.data) {
            this.overview = this.data.getOverview();
        }
    }
    print() {
        Helpers.print('printing', 'Reserva ' + this.data.fullNumber);
    }
};
tslib_1.__decorate([
    Watch('data')
], BookMainPrint.prototype, "onDataChanged", null);
BookMainPrint = tslib_1.__decorate([
    Component
], BookMainPrint);
export default BookMainPrint;
