export default class Category {
    constructor(data) {
        this.name = '';
        this.description = '';
        this.id = 0;
        if (data) {
            Object.assign(this, data);
        }
    }
    toNormalize() {
        let reg = Object.assign({}, this);
        delete (reg.id);
        return reg;
    }
}
