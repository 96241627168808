import { Article } from "./Article";
export default class Site {
    constructor(data) {
        this.id_book = -1;
        this.name = '';
        this.notes = '';
        this.articles = new Array();
        this.expanded = false;
        this.id = 0;
        if (data) {
            Object.assign(this, data);
            this.articles = data.articles.map((a, index) => {
                return {
                    article: new Article(a.article),
                    quantity: a.quantity,
                    imageIndex: a.imageIndex,
                    order: index + 1
                };
            });
        }
    }
    get notesHTML() {
        var aux = '';
        if (this.notes) {
            aux = this.notes;
            while (aux.includes('\n')) {
                aux = aux.replace('\n', '<br/>');
            }
        }
        return aux;
    }
    clone() {
        let clone = new Site();
        clone.id = 0;
        clone.id_book = this.id_book;
        clone.name = this.name;
        clone.notes = this.notes;
        clone.expanded = this.expanded;
        this.articles.forEach(article => {
            let a = new Article(article.article);
            let q = article.quantity;
            let i = article.imageIndex;
            let order = this.articles.length;
            clone.articles.push({ article: a, quantity: q, imageIndex: i, order: 0 });
        });
        return clone;
    }
    toNormalize() {
        let reg = Object.assign({}, this);
        delete (reg.id);
        return reg;
    }
}
