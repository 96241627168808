import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/src/locale/en';
import es from 'vuetify/src/locale/es';
import '@/plugins/overrides.sass';
Vue.use(Vuetify);
const theme = {
    primary: '#b6bd00',
    secondary: '#3e3934',
    accent: '#e9f3d3',
    success: '#4CAF50',
    error: '#EF5350',
    text: '#18042C',
    admin: '#4CAF50',
    availability: '#3F51B5',
    user: '#2196F3',
    contact: '#009688',
    category: '#795548',
    device: '#607D8B',
    alert: '#F44336',
    book: '#FF9800',
    article: '#673AB7',
    notification: '#FFA000',
    stats: '#8BC34A',
    default: '#607D8B'
};
export default new Vuetify({
    theme: {
        themes: {
            dark: theme,
            light: theme,
        },
        options: {
            customProperties: true,
        },
    },
    lang: {
        locales: { es, en },
        current: 'es',
    }
});
