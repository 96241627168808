import * as tslib_1 from "tslib";
import { Component, Prop, Watch } from 'vue-property-decorator';
import MaterialCard from "@/components/Base/MaterialCard/MaterialCard.vue";
import MaterialUploader from "@/components/Base/MaterialUploader/MaterialUploader.vue";
import InputField from '@/components/InputField/InputField.vue';
import JSPage from '@/classes/JSPage';
import ArticleService from '@/services/ArticleService';
import { Article } from '@/models/Article';
import CategoryService from '@/services/CategoryService';
import { ForeignEntry } from '@/models/ForeignEntry';
import ImageField from '@/classes/ImageField';
import draggable from 'vuedraggable';
let ArticleMainTab = class ArticleMainTab extends JSPage {
    constructor() {
        super(...arguments);
        this.articleService = new ArticleService();
        this.categories = new Array();
    }
    onArticleChanged() {
        if (this.article && !(this.article instanceof Article)) {
            this.article = new Article(this.article);
        }
        console.log(this.article);
    }
    created() {
        super.created();
        this.loadCategories();
    }
    main() { }
    loadCategories() {
        (new CategoryService()).getAll(undefined, 'name=ASC', 1, 9999).subscribe((categoriesData) => this.categories = categoriesData.data.map(cat => new ForeignEntry(cat.id, cat.name)));
    }
    addImage() {
        if (this.article && this.article.images) {
            this.article.images.push(new ImageField());
            this.$forceUpdate();
        }
    }
    setImagesOrder(images) {
        this.article.images = images;
        console.log(this.article);
    }
    update() {
        let a = new Article(this.article);
        if (a) {
            if (!a.id) {
                this.articleService.add(a).subscribe((article) => {
                    this.$router.replace(article.id.toString());
                }, (error) => {
                });
            }
            else {
                this.articleService.update(a).subscribe((article) => {
                    this.$emit('input', new Article(article));
                });
            }
        }
    }
};
tslib_1.__decorate([
    Prop({ default: undefined })
], ArticleMainTab.prototype, "article", void 0);
tslib_1.__decorate([
    Watch('article')
], ArticleMainTab.prototype, "onArticleChanged", null);
ArticleMainTab = tslib_1.__decorate([
    Component({
        components: {
            MaterialCard, MaterialUploader, InputField, draggable
        }
    })
], ArticleMainTab);
export default ArticleMainTab;
