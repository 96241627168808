import Base64 from './Base64';
import moment from 'moment';
export default class Helpers {
    static getNestedValue(obj, path, fallback) {
        const last = path.length - 1;
        if (last < 0)
            return obj === undefined ? fallback : obj;
        for (let i = 0; i < last; i++) {
            if (obj == null) {
                return fallback;
            }
            obj = obj[path[i]];
        }
        if (obj == null)
            return fallback;
        return obj[path[last]] === undefined ? fallback : obj[path[last]];
    }
    static getObjectValueByPath(obj, path, fallback) {
        if (obj == null || !path || typeof path !== 'string')
            return fallback;
        if (obj[path] !== undefined)
            return obj[path];
        path = path.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        path = path.replace(/^\./, ''); // strip a leading dot
        return Helpers.getNestedValue(obj, path.split('.'), fallback);
    }
    static stringToBase64(string) {
        return Base64.encode(string);
    }
    static base64ToString(string) {
        return Base64.decode(string);
    }
    static toLocalDateTime(string, separator = '-') {
        return moment(string, 'YYYY-MM-DD HH:mm:ss').format('DD' + separator + 'MM' + separator + 'YYYY - HH:mm:ss');
    }
    static toLocalDateTimeShort(string) {
        return moment(string, 'YYYY-MM-DD HH:mm').format('DD-MM-YY - HH:mm');
    }
    static toLocalDate(string, separator = '-') {
        if (!string)
            return '';
        return moment(string, 'YYYY-MM-DD HH:mm').format('DD' + separator + 'MM' + separator + 'YYYY');
    }
    static toLocalTime(string) {
        return moment(string, 'YYYY-MM-DD HH:mm').format('HH:mm');
    }
    static toLocalDateShort(string) {
        return moment(string, 'YYYY-MM-DD HH:mm').format('DD-MM-YY');
    }
    static toTime(string) {
        return moment(string, 'HH:mm:ss').format('HH:mm');
    }
    static toPrice(number) {
        return (number / 100).toFixed(2) + '€';
    }
    static toPercentage(number) {
        return (number / 100).toFixed(2) + '%';
    }
    static getRandomInt(max = 99999999, min = 1) {
        return Math.floor(Math.random() * (max - min)) + min;
    }
    static print(elementId = 'printing', title = '') {
        const printer = window.open(' ', ' ', 'left=0,top=0,width=1000,height=700,toolbar=0,scrollbars=0,status=0');
        setTimeout(() => printer.document.title = title, 100);
        let stylesHtml = '';
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
        }
        const printing = document.getElementById(elementId);
        printing.style.display = 'block';
        let pdf = title == 'Elecfes' ? '' : `
            <button class='pdf-button' onClick='printPDF();return false;'>
                Guardar como PDF
            </button>`;
        var breaks = '';
        for (let i = 0; i < 200; i++) {
            breaks += '<div class="page_break"></div>';
        }
        // printer!.document.write(
        //     `<!DOCTYPE html>
        //     <html>
        //         <head>
        //             <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/2.5.1/jspdf.umd.min.js "></script>
        //             <script src="https://cdnjs.cloudflare.com/ajax/libs/html2canvas/1.4.1/html2canvas.min.js"></script>
        //             <script src="https://code.jquery.com/jquery-3.4.1.slim.min.js" integrity="sha384-J6qa4849blE2+poT4WnyKhv5vZF5SrPo0iEjwBvKU7imGFAV0wwj1yYfoRSJoZ+n" crossorigin="anonymous"></script>
        //             ${ stylesHtml }
        //             <title>Reserva</title>
        //         </head>
        //         <body class='printer'>
        //             <button class='print-button' onClick='printWithSpecialFilename();return false;'>
        //                 Imprimir
        //             </button>                
        //             ${ breaks }
        //         </body>
        //         <script type='text/javascript'> 
        //             function printWithSpecialFilename() {
        //                     var tempTitle = document.title;
        //                     document.title = '${ title }';
        //                     window.print();
        //                     document.title = tempTitle;
        //                 }
        //             window.addEventListener( 'load', function() {
        //                 $( '.page_break' ).each( function() {
        //                     $( this ).text( $( this ).offset().top );
        //                 });       
        //             });
        //         </script>
        //     </html>`
        // );
        printer.document.write(`<!DOCTYPE html>
            <html>
                <head>
                    <script src="/js/jspdf.min.js"></script>
                    <script src="/js/html2canvas.min.js"></script>
                    <script src="/js/jquery.min.js"></script>
                    <script src="/js/js.js"></script>
                    ${stylesHtml}
                    <title>Reserva</title>
                </head>
                <body class='printer'>
                                        
                    ${pdf}
                    <button class='print-button' onClick='printWithSpecialFilename();return false;'>
                        Imprimir
                    </button>
                    
                    ${printing.outerHTML}
                    
                </body>
                <script type='text/javascript'>           
                
                    function printWithSpecialFilename() {
                        var tempTitle = document.title;
                        document.title = '${title}';
                        nativePrint();
                        document.title = tempTitle;
                    }
                    
                    async function printPDF() {
                        var pdf = new PDF();
                        pdf.printPDF( '${title.replace(' ', '_').replace('/', '_')}.pdf' );                                               
                    }                                      

                    setTimeout( () => {
                        $( '.aspect-ratio' ).each( function() {
                            $( this ).height( $( this ).width() / parseFloat( $( this ).attr( 'aspect-ratio' ) ) );
                        });
                    }, 1000 );
                </script>
            </html>`);
        printer.document.close();
    }
}
