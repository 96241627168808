import * as tslib_1 from "tslib";
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DataTable from '@/components/DataTable/DataTable.vue';
import Helpers from '@/classes/Helpers';
let DataPrint = class DataPrint extends Vue {
    onDoPrintChanged() {
        if (this.value) {
            setTimeout(() => {
                this.print();
                this.$emit('input', false);
            }, 1000);
        }
    }
    get appliedFilters() {
        return this.filters.filter(f => f.content !== '');
    }
    created() {
    }
    print() {
        Helpers.print('printing', 'Elecfes');
    }
};
tslib_1.__decorate([
    Prop()
], DataPrint.prototype, "headers", void 0);
tslib_1.__decorate([
    Prop()
], DataPrint.prototype, "filters", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DataPrint.prototype, "data", void 0);
tslib_1.__decorate([
    Prop({ default: false })
], DataPrint.prototype, "value", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], DataPrint.prototype, "title", void 0);
tslib_1.__decorate([
    Prop({ default: '' })
], DataPrint.prototype, "postTitle", void 0);
tslib_1.__decorate([
    Watch('value')
], DataPrint.prototype, "onDoPrintChanged", null);
DataPrint = tslib_1.__decorate([
    Component({
        name: 'DataPrint',
        components: {
            DataTable
        }
    })
], DataPrint);
export default DataPrint;
